<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card
      no-body
    >
      <b-card-body>

        <div class="row">
          <div class="col-md-3">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click="getwinloss('Today')"
            >
              วันนี้
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click="getwinloss('Yesterday')"
            >
              เมื่อวาน
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click="getwinloss('ThisWeek')"
            >
              อาทิตย์นี้
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click="getwinloss('LastWeek')"
            >
              อาทิตย์ที่แล้ว
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click="getwinloss('ThisMonth')"
            >
              เดือนนี้
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click="getwinloss('LastMonth')"
            >
              เดือนที่แล้ว
            </b-button>
          </div>
          <div class="col-md-9">
            <b-form-group
              class="mr-1 mb-md-0"
            >

              <b-form-checkbox
                v-model="allSelected"
                :indeterminate="indeterminate"
                aria-describedby="flavours"
                aria-controls="flavours"
                @change="toggleAll"
              >
                ทั้งหมด
              </b-form-checkbox>
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="selected"
                :options="options"
                value-field="name"
                text-field="name"
                name="flavour-1"
                class="demo-inline-spacing"
                @change="getwinloss()"
              />
            </b-form-group>
            <!-- Selected: <strong>{{ selected }}</strong> -->
          </div>
        </div>

      </b-card-body>

      <div style="overflow-x:auto;">
        <table>
          <thead>
            <tr>
              <th>ยูสเซอร์เนม</th>
              <th>WIN/LOSS</th>
              <th>ส่วนลด</th>
              <th>รอผล</th>
              <th>ส่วนลดรอผล</th>
            </tr>
          </thead>
          <tbody>

            <tr
              v-for="(data) in listdata"
              :key="data._id"
            >
              <th scope="row">
                <a
                  style="color: blue"
                  @click="wluser()"
                >
                  {{ data.username }}
                </a>
              </th>
              <td
                v-if="data.winloss < 0"
                style="color:red"
              >{{ data.winloss.toLocaleString() }}</td>
              <td
                v-else
              >{{ data.winloss.toLocaleString() }}</td>
              <td>
                {{ data.discount.toLocaleString() }}
              </td>
              <td
                v-if="data.waiting < 0"
                style="color:red"
              >
                {{ data.waiting.toLocaleString() }}
              </td>
              <td
                v-else
              >
                {{ data.waiting.toLocaleString() }}
              </td>
              <td>
                {{ data.witingdiscount.toLocaleString() }}
              </td>

            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th scope="row">
                ยอดรวม
              </th>
              <td
                v-if="twinloss < 0 "
                style="color: red"
              >
                {{ Number(twinloss).toLocaleString() }}
              </td>
              <td v-else>
                {{ Number(twinloss).toLocaleString() }}
              </td>
              <td>
                {{ Number(tdiscount).toLocaleString() }}
              </td>
              <td
                v-if="twaiting < 0 "
                style="color: red"
              >
                {{ Number(twaiting).toLocaleString() }}
              </td>
              <td v-else>
                {{ Number(twaiting).toLocaleString() }}
              </td>

              <td>{{ Number(twitingdiscount).toLocaleString() }} </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0" />
      <b-modal
        id="modal-1"
        title="Add Class"
        ok-only
        ok-title="Save"
      >
        <b-form-group
          label="Class"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CheckSquareIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-first-name"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BFormCheckbox, BFormCheckboxGroup, BInputGroupPrepend, BModal, BCard,
  // BTable, BAvatar,
  BFormGroup,
  // BFormSelect, BPagination,
  BInputGroup, BFormInput,
  // BInputGroupAppend,
  BButton,
  BCardBody, VBToggle,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import moment from 'moment-timezone'
// import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroupPrepend,
    BModal,
    BCard,
    // BTable,
    // BAvatar,
    BFormGroup,
    // BFormSelect,
    // BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    // flatPickr,
    BOverlay,
    BIconController,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      show: true,
      rangeDate: null,
      wlParams: {},
      username: null,
      days: null,
      twinloss: 0.00,
      twaiting: 0.00,
      tdiscount: 0.00,
      twitingdiscount: 0.00,
      selected: [],
      options: [],
      listdata: [],
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        // { key: 'appid', label: 'appid' },
        { key: 'winloss', label: 'win/loss' },
        { key: 'discount', label: 'ส่วนลด' },
        { key: 'waiting', label: 'รอผล' },
        { key: 'witingdiscount', label: 'ส่วนลดรอผล' },
        // { key: 'actions', label: 'Actions' },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      allSelected: false,
      indeterminate: false,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    selected(newValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.options.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    // this.Get_userlist()
    this.GetData()

    this.wlParams = JSON.parse(localStorage.getItem('wlParams'))
    this.days = this.wlParams.days
    this.selected = this.wlParams.selected
    this.username = this.wlParams.username
    if (JSON.parse(localStorage.getItem('wlParams'))) {
      this.getwinloss()
    }
  },
  methods: {
    toggleAll(checked) {
      this.selected = checked ? this.options.slice() : []
      this.getwinloss()
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    async GetData() {
      await this.$http
        .get('https://api.rmtlotto.com/api/lottotype')
        .then(async response => {
          this.show = false
          // this.options = response.data
          // eslint-disable-next-line no-restricted-syntax
          for await (const da of response.data) {
            this.options.push(da.name)
          }
          console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    getwinloss() {
      this.show = true
      // this.show = false
      const params = {
        selected: this.selected,
        days: this.days,
        username: this.username,
      }
      this.$http
        .get('https://api.rmtlotto.com/api/history/showbyuser', { params })
        .then(response => {
          this.listdata = response.data.Mes
          this.twinloss = response.data.twinloss
          this.twaiting = response.data.twaiting
          this.tdiscount = response.data.tdiscount
          this.twitingdiscount = response.data.twitingdiscount
          this.show = false
          // this.onFiltered(response.data)
        })
        .catch(error => {
          console.log(error)
          this.show = false
          this.listdata = []
          this.twinloss = 0.00
          this.twaiting = 0.00
          this.tdiscount = 0.00
          this.twitingdiscount = 0.00
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // console.log(filteredItems)
      this.items = filteredItems.Mes
      this.totalRows = filteredItems.total
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = data
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
  },
}
</script>

<style scoped>
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}
thead,
tfoot {
  background-color: gold;
  color: rgb(0, 0, 0);
  text-align: center;
}

tbody {
  background-color: #f1e8e8;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}

td,
th {
  border: 1px solid rgb(190, 190, 190);
  padding: 5px 10px;
}

td {
  text-align: center;
  color: #000;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.form-item-section {
background-color:$product-details-bg;
}
</style>
